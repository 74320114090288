import { Auth } from "./auth";
import {
  Analytics,
  Business,
  CreatePost,
  EditPost,
  Post,
  UserSnapshot,
  CreateBusiness,
  Message,
} from "./types";

enum APIRoute {
  requestCode = "admin/request-code",
  login = "admin/login",

  posts = "admin/posts",
  post = "admin/post",
  create = "admin/create",
  upload = "files/upload-post",
  approve = "admin/approve",
  reject = "admin/reject",
  edit = "admin/edit",
  delete = "admin/delete",
  freeze = "admin/freeze",
  unfreeze = "admin/unfreeze",
  fulfill = "admin/fulfill",
  UnfulfillPost = "admin/unfulfill",
  PostUrl = "files/post-url",
  takePayment = "payments/take-payment",
  skipPayment = "payments/skip-payment",

  getUserShort = "admin/user-short",
  getUser = "admin/user",
  users = "admin/users",

  verify = "admin/verify",
  analytics = "admin/analytics",
  notifyAll = "admin/notify-all",
  businessUrl = "files/business-url",
  business = "directory/admin",
  oneBusiness = "directory/admin/one",
  updateBusiness = "directory/admin/update",

  allMessages = "admin/all-messages",
  reportNumbers = "admin/report-numbers",
  getReports = "admin/reports",
  banUser = "admin/ban-user",
  unbanUser = "admin/unban-user",
  approveUser = "admin/approve-user",
}

const BASE_URL = process.env.REACT_APP_API_URL;

export class API {
  /* ------------------ HTTP METHODS ------------------ */
  static async post<Body extends Record<string, any>, Res>(
    route: APIRoute,
    body: Body,
  ): Promise<Res> {
    const config = {
      method: "POST",
      headers: {
        credentials: "include" as "include",
        "Content-Type": "application/json",
        ...Auth.instance.authHeader,
      },
      body: JSON.stringify(body),
    };
    const url = new URL(`${BASE_URL}/${route}`);
    const res = await fetch(url.toString(), config);
    if (!res.ok) {
      const errorData = await res.json();
      throw new Error(
        `Error ${res.status}: ${errorData.message || "Unknown error"}`,
      );
    }
    return res.json();
  }

  static async postForm<Body extends FormData, Res>(
    route: APIRoute,
    body: Body,
  ): Promise<Res> {
    const config = {
      method: "POST",
      headers: {
        credentials: "include" as "include",
        ...Auth.instance.authHeader,
      },
      body,
    };
    const url = new URL(`${BASE_URL}/${route}`);
    const res = await fetch(url.toString(), config);
    if (!res.ok) {
      const errorData = await res.json();
      throw new Error(
        `Error ${res.status}: ${errorData.message || "Unknown error"}`,
      );
    }
    return res.json();
  }

  static async get<Params extends object, Res>(
    route: APIRoute,
    params: Params,
  ): Promise<Res> {
    const url = new URL(`${BASE_URL}/${route}`);
    if (params) {
      Object.entries(params).forEach(([key, value]) =>
        url.searchParams.append(key, value.toString()),
      );
    }
    const res = await fetch(url.toString(), {
      headers: { ...Auth.instance.authHeader, credentials: "include" },
    });

    if (!res.ok) {
      const errorData = await res.json();
      throw new Error(
        `Error ${res.status}: ${errorData.message || "Unknown error"}`,
      );
    }

    const data = await res.json();
    return data as Res;
  }

  static async delete<Params extends object>(
    route: APIRoute | string,
    params: Params,
  ): Promise<void> {
    const url = new URL(`${BASE_URL}/${route}`);
    Object.entries(params).forEach(([key, value]) =>
      url.searchParams.append(key, value.toString()),
    );
    const config = {
      method: "DELETE",
      headers: {
        ...Auth.instance.authHeader,
        credentials: "include" as "include",
      },
    };
    await fetch(url.toString(), config);
  }

  // ------------------ API METHODS ------------------

  static async requestCode(email: string, password: string) {
    return await API.post<{ email: string; password: string }, void>(
      APIRoute.requestCode,
      { email, password },
    );
  }

  static async login(email: string, password: string, code: string) {
    return await API.post<
      { email: string; password: string; code: string },
      { token: string }
    >(APIRoute.login, { email, password, code });
  }

  static async verify() {
    return await API.get<object, { success: boolean }>(APIRoute.verify, {});
  }

  static async getAnalytics() {
    return await API.get<object, Analytics>(APIRoute.analytics, {});
  }

  static async createPost(post: CreatePost) {
    return await API.post<{ post: CreatePost }, { post: Post; link: string }>(
      APIRoute.create,
      { post },
    );
  }

  static async uploadPostImages(images: File[]) {
    const formData = new FormData();
    images.forEach((image, i) => {
      formData.append("files", image);
    });

    return await API.postForm<FormData, { urls: string[] }>(
      APIRoute.upload,
      formData,
    );
  }

  static async approvePost(id: string) {
    return await API.post<{ id: string }, { post: Post }>(APIRoute.approve, {
      id,
    });
  }

  static async rejectPost(id: string) {
    return await API.post<{ id: string }, { post: Post }>(APIRoute.reject, {
      id,
    });
  }

  static async deletePost(id: string) {
    return await API.delete<{ id: string }>(APIRoute.delete, { id });
  }

  static async freezePost(id: string) {
    return await API.post<{ id: string }, { post: Post }>(APIRoute.freeze, {
      id,
    });
  }

  static async unfreezePost(id: string) {
    return await API.post<{ id: string }, { post: Post }>(APIRoute.unfreeze, {
      id,
    });
  }

  static async fulfillPost(id: string) {
    return await API.post<{ id: string }, { post: Post }>(APIRoute.fulfill, {
      id,
    });
  }

  static async unfulfill(id: string): Promise<void> {
    await API.post<{ id: string }, void>(APIRoute.UnfulfillPost, {
      id,
    });
  }

  static async editPost(post: EditPost) {
    return await API.post<{ post: EditPost }, { post: Post }>(APIRoute.edit, {
      post,
    });
  }

  static async getUser(id: string) {
    return await API.get<{ id: string }, { user: UserSnapshot }>(
      APIRoute.getUser,
      {
        id,
      },
    );
  }

  static async getPosts(query: {
    cursor?: string;
    status?: string;
    search?: string;
  }) {
    return await API.get<
      { cursor?: string; status?: string; search?: string },
      { posts: Post[] }
    >(APIRoute.posts, query);
  }

  static async getUserShortId(shortId: number) {
    return await API.get<{ shortId: number }, { user: UserSnapshot }>(
      APIRoute.getUserShort,
      {
        shortId,
      },
    );
  }

  static async getUsers(cursor?: string, search?: string, status?: string) {
    const cursorObj = cursor ? { cursor } : {};
    const searchObj = search ? { search } : {};
    const statusObj = status ? { status } : {};
    return await API.get<
      { cursor?: string; search?: string; status?: string },
      { users: UserSnapshot[] }
    >(APIRoute.users, { ...cursorObj, ...searchObj, ...statusObj });
  }

  static async getPost(id: string) {
    return await API.get<{ id: string }, { post: Post }>(APIRoute.post, {
      id,
    });
  }

  static async takePayment(id: string) {
    return await API.post<{ id: string }, { post: Post }>(
      APIRoute.takePayment,
      { id },
    );
  }

  static async skipPayment(id: string) {
    return await API.post<{ id: string }, { post: Post }>(
      APIRoute.skipPayment,
      { id },
    );
  }

  static async notifyAll(
    title: string,
    body: string,
    data: any,
  ): Promise<void> {
    await API.post<
      { title: string; body: string; data: any },
      { success: boolean }
    >(APIRoute.notifyAll, { title, body, data });
  }

  static async uploadProductFile(file: File): Promise<string> {
    const { signedUrl, fields, filename } = await this.get<
      { ContentType: string },
      { signedUrl: string; fields: Record<string, string>; filename: string }
    >(APIRoute.PostUrl, {
      ContentType: file.type,
    });

    const formData = new FormData();
    Object.entries(fields).forEach(([key, value]) => {
      formData.append(key, value);
    });

    formData.append("file", file);

    await fetch(signedUrl, {
      method: "POST",
      body: formData,
    });
    return `${process.env.REACT_APP_SPACES_ENDPOINT}/${filename}`;
  }

  static async uploadBusinessFile(file: File): Promise<string> {
    const { signedUrl, fields, filename } = await this.get<
      { ContentType: string },
      { signedUrl: string; fields: Record<string, string>; filename: string }
    >(APIRoute.businessUrl, {
      ContentType: file.type,
    });

    const formData = new FormData();
    Object.entries(fields).forEach(([key, value]) => {
      formData.append(key, value);
    });

    formData.append("file", file);

    await fetch(signedUrl, {
      method: "POST",
      body: formData,
    });

    return `${process.env.REACT_APP_SPACES_ENDPOINT}/${filename}`;
  }

  static async createBusiness(business: CreateBusiness): Promise<void> {
    await API.post<CreateBusiness, { success: boolean }>(
      APIRoute.business,
      business,
    );
  }

  static async getDirectory(cursor?: string): Promise<Business[]> {
    const cursorObj = cursor ? { cursor } : {};
    const directory = await API.get<{ cursor?: string }, Business[]>(
      APIRoute.business,
      cursorObj,
    );
    return directory;
  }

  static async deleteBusiness(id: string): Promise<void> {
    await API.delete<{ id: string }>(APIRoute.business, { id });
  }

  static async getBusiness(id: string): Promise<Business> {
    return await API.get<{ id: string }, Business>(APIRoute.oneBusiness, {
      id,
    });
  }

  static async updateBusiness(business: Business): Promise<void> {
    await API.post<Business, { success: boolean }>(
      APIRoute.updateBusiness,
      business,
    );
  }

  //** ----------------------------MESSAGES--------------------------- */
  static async allMessages(messageType: string) {
    return await API.get<object, { messages: Message[] }>(
      APIRoute.allMessages,
      { messageType },
    );
  }

  static async getReportNumbers() {
    return await API.get<
      object,
      {
        postReports: {
          postId: string;
          number: number;
        }[];
        userReports: {
          userId: string;
          number: number;
        }[];
      }
    >(APIRoute.reportNumbers, {});
  }

  static async getReports(reportType: "post" | "user", id: string) {
    return await API.get<
      { reportType: "post" | "user"; id: string },
      { id: string; reason: string }[]
    >(APIRoute.getReports, { reportType, id });
  }

  static async banUser(id: string) {
    return await API.post<{ id: string }, { success: boolean }>(
      APIRoute.banUser,
      { id },
    );
  }

  static async unbanUser(id: string) {
    return await API.post<{ id: string }, { success: boolean }>(
      APIRoute.unbanUser,
      { id },
    );
  }

  static async approveUser(id: string) {
    return await API.post<{ id: string }, { success: boolean }>(
      APIRoute.approveUser,
      { id },
    );
  }
}
